<template :if="accountData">
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Algemeen</span>
      </template>

      <account-setting-general
        v-if="accountData"
        :account-data="accountData"
        @update-account="updateAccount"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Verander wachtwoord</span>
      </template>

      <account-setting-password
        :account-data="accountData"
      />
    </b-tab>

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Two-Factor Authenticatie</span>
      </template>

      <account-setting-tfa
        v-if="accountData"
        :account-data="accountData"
        @update-tfa="updateTFA"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import accountStoreModule from '@/views/pages/account-setting/accountStoreModule'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingTfa from './AccountSettingTfa.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingTfa,
  },
  data() {
    return {
      accountData: {},
    }
  },
  mounted() {
    const accountData = JSON.parse(localStorage.getItem('userData'))
    store.dispatch('apps-account/fetchAccount', { id: accountData.id })
      .then(response => {
        this.accountData = response.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen Accountgegevens',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  },
  methods: {
    updateAccount(accountData) {
      store.dispatch('apps-account/updateAccount', accountData)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Update Account!',
            text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Update Account!',
            text: `${errorAndSuccesDefaultMessage.updateDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    updateTFA(data) {
      const postData = {
        id: this.accountData.id,
        tfaConfirm: data.tfaConfirm,
        tfaSecret: data.tfaSecret,
        changeTFA: true,
      }
      store.dispatch('apps-account/updateAccount', postData)
        .then(response => {
          this.accountData = response.data
          this.$swal({
            icon: 'success',
            title: 'Gelukt!',
            text: 'De Two-Factor Authenticatie wijziging is succesvol doorgevoerd!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Mislukt!',
            text: 'De ingevoerde TFA code is incorrect!',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const ACCOUNT_APP_STORE_MODULE_NAME = 'apps-account'

    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
