<template :if="accountData">
  <b-card>

    <!-- Media -->
    <b-media class="mb-0">
      <template #aside>
        <b-overlay
          :show="showUploadLoader"
          rounded="sm"
        >
          <b-avatar
            ref="previewEl"
            :src="accountData.avatarURL"
            :text="avatarText(getFullName())"
            size="90px"
            square
          />
        </b-overlay>
      </template>
      <h4 class="mb-1">
        {{ getFullName() }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="uploadImage"
          >
          <span class="d-none d-sm-inline">Uploaden</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
      <b-card-text class="text-muted">
        <small>Toegestaan JPG, GIF of PNG. Maximale grootte van 2MB.<br>
          De wijziging van je avatar zal pas bij de volgende login van toepassing zijn.</small>
      </b-card-text>
    </b-media>
    <!--/ media -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col sm="4">
            <validation-provider
              #default="validationContext"
              name="firstname"
              rules="required"
            >
              <b-form-group
                label="Voornaam"
                label-for="firstname"
              >
                <b-form-input
                  v-model="accountData.firstname"
                  placeholder="firstname"
                  name="firstname"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label="Tussenvoegsel"
              label-for="middlename"
            >
              <b-form-input
                v-model="accountData.middlename"
                name="middlename"
                placeholder="middlename"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <validation-provider
              #default="validationContext"
              name="lastname"
              rules="required"
            >
              <b-form-group
                label="Achternaam"
                label-for="lastname"
              >
                <b-form-input
                  v-model="accountData.lastname"
                  name="lastname"
                  placeholder="lastname"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="accountData.email"
                  name="email"
                  placeholder="Email"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="accountData.company && accountData.company.name"
            sm="6"
          >
            <b-form-group
              label="Organisatie"
              label-for="organisation"
            >
              <b-form-input
                :value="accountData.company.name"
                name="organisation"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BOverlay, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email } from '@core/utils/validations/validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BOverlay,
    BAvatar,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    accountData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showUploadLoader: false,
    }
  },
  methods: {
    getFullName() {
      let fullName = ''

      if (this.accountData) {
        if (this.accountData.middlename && this.accountData.middlename.length > 0) {
          fullName = `${this.accountData.firstname} ${this.accountData.middlename} ${this.accountData.lastname}`
        } else {
          fullName = `${this.accountData.firstname} ${this.accountData.lastname}`
        }
      }

      return fullName
    },
    uploadImage() {
      this.showUploadLoader = true
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 10485760) {
        this.showUploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'User',
              name: file.name,
              relationID: this.accountData.id,
            }

            store.dispatch('apps-account/uploadImage', fileUploadData)
              .then(response => {
                store.dispatch('apps-account/patchAccount', { id: this.accountData.id, avatar: response.data.fileID })
                  .then(res => {
                    this.accountData = res.data
                    const userData = JSON.parse(localStorage.getItem('userData'))
                    userData.avatar = res.data.avatarURL
                    userData.fullName = 'test'
                    localStorage.setItem('userData', JSON.stringify(userData))
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'success',
                      title: 'Upload',
                      text: 'Upload van de foto is gelukt!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch(() => {
                    this.accountData = undefined
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'error',
                      title: 'Uploaden',
                      text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                      customClass: {
                        confirmButton: 'btn btn-danger',
                      },
                    })
                  })
              })
              .catch(() => {
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: `${errorAndSuccesDefaultMessage.uploadDataError}`,
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )

        reader.readAsDataURL(file)
      }
    },
  },
  setup(props, { emit }) {
    const onSubmit = () => {
      emit('update-account', props.accountData)
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      onSubmit,
      required,
      email,
    }
  },
}
</script>
