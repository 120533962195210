<template :if="organisationOptions && accountData">
  <b-card>

    <!-- Media -->
    <b-row>
      <b-col sm="4">
        <h5
          v-if="!accountData.tfaEnabled"
          class="mb-1"
        >
          Download Google Authenticator uit de App Store / Google Play Store en scan de QR code hiernaast
        </h5>
        <h5
          v-if="accountData.tfaEnabled"
          class="mb-1"
        >
          Voer de code uit uw authenticator app in om TFA uit te schakelen. Neem contact op met de klantenservice indien u hier geen toegang meer tot heeft
        </h5>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- form -->
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-row>
              <b-col sm="12">
                <validation-provider
                  #default="validationContext"
                  name="tfaConfirm"
                  rules="required|integer"
                >
                  <b-form-group
                    label="TFA Code (Ter bevestiging)"
                    label-for="tfaConfirm"
                  >
                    <b-form-input
                      v-model="accountDataWithTfa.tfaConfirm"
                      placeholder="000 000"
                      name="tfaConfirm"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!accountData.tfaEnabled"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  type="submit"
                >
                  Activeer
                </b-button>
                <b-button
                  v-if="accountData.tfaEnabled"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-2 mr-1"
                  type="submit"
                >
                  De-Activeer
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col sm="4">
        <b-media
          v-if="!accountData.tfaEnabled"
          class="mb-0"
        >
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="qrURL"
              size="185px"
              square
            />
          </template>
        </b-media>
      </b-col>
    </b-row>
    <!--/ media -->

  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BAvatar, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer } from '@core/utils/validations/validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BFormInvalidFeedback,

    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    accountData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      qrURL: '',
      showUploadLoader: false,
      accountDataWithTfa: {},
    }
  },
  watch: {
    accountData: {
      deep: true,
      handler(data) {
        this.accountDataWithTfa = JSON.parse(JSON.stringify(data))
        if (!this.accountData.tfaEnabled) {
          this.accountDataWithTfa.tfaSecret = this.generateSecret()
          this.qrURL = encodeURI(`https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth://totp/hem (${this.accountDataWithTfa.email})?secret=${this.accountDataWithTfa.tfaSecret}&issuer=Hem`)
        }
        this.accountDataWithTfa.tfaConfirm = null
      },
    },
  },
  methods: {
    generateSecret() {
      const result = []
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'
      const charactersLength = characters.length
      for (let i = 0; i < 32; i += 1) {
        result.push(characters.charAt(Math.floor(Math.random()
        * charactersLength)))
      }
      return result.join('')
    },
    onSubmit() {
      this.$emit('update-tfa', this.accountDataWithTfa)
      this.accountDataWithTfa.tfaConfirm = null
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      required,
      integer,
    }
  },
}
</script>
